import { rotateImage } from "@/core/api/exam/exampaper";
import { Message } from "element-ui";
export const operates = {
  0: function () {
    // 缩小
    this.$refs.paperTool.style["transform-origin"] = `0 0`;
    if (this.scaleRatio > 0.5) {
      this.scaleRatio = this.scaleRatio - 0.1;
    }
  },
  1: function () {
    // 放大
    this.$refs.paperTool.style["transform-origin"] = `0 0`;
    if (this.scaleRatio <= 1.4) {
      this.scaleRatio = this.scaleRatio + 0.1;
    }
  },
  2: function () {
    // 逆时针旋转
    // this.$refs.paperTool.style["transform-origin"] = `center center`;
    // this.rotateDeg = this.rotateDeg - 90;

    let url = this.data.split("?")[0];
    this.degTotal = this.degTotal - 90;
    rotateImage({ url: url, degree: -90 }).then(() => {
      this.$emit("submit", "upDataPpaer");
    });
  },
  3: function () {
    // 顺时针旋转

    let url = this.data.split("?")[0];
    this.degTotal = this.degTotal + 90;
    rotateImage({ url: url, degree: 90 }).then(() => {
      this.$emit("submit", "upDataPpaer");
    });
  },
  // 4: function () {
  //   // 自定义放大缩小系数
  //   this.scaleVisible = true;
  //   this.scaleNum = this.scaleRatio;
  //   this.title = "自定义放大/缩小系数";
  // },
  4: function () {
    // 还原放大系数和旋转系数并请求新的数据
    this.scaleRatio = 1;
    this.status = 1;
    this.rotateDeg = 0;
    this.exit();
    this.$parent.getPaperMsg();
    let url = this.data.split("?")[0];
    if (this.degTotal !== 0) {
      let degree = 0;
      if (
        this.degTotal == 270 ||
        this.degTotal % 360 == 270 ||
        this.degTotal == -270 ||
        this.degTotal % 360 == -270
      ) {
        degree = this.degTotal % 360 > 0 ? 90 : -90;
      } else if (this.degTotal <= 360 && this.degTotal > 0) {
        degree = 360 - this.degTotal;
      } else {
        degree = -this.degTotal % 360;
      }
      if (degree === 270 || degree === -270) {
        degree = degree > 0 ? 90 : -90;
      }
      rotateImage({ url: url, degree: degree }).then(() => {
        this.$emit("submit");
        this.degTotal = 0;
      });
    }
  },
  // 6: function () {
  //   if (this.status != 1 || this.status != 3) {
  //     this.status = 1;
  //   }
  //   this.rectList = [];
  //   this.bindMouseToPaperTool();
  // },
};
// 裁切框锚点拉动鼠标按下事件
export const pointMouseDown = function (e, direction, index) {
  this.sizeFlag == true;
  this.$refs.container.onmousemove = (event) =>
    pointMouseMove[direction].bind(this)(event, index);
  this.$refs.container.onmouseup = (event) =>
    pointMouseUp[direction].bind(this)(event, index);
};
// 裁切框锚点拉动鼠标移动事件
const pointMouseMove = {
  s: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
    }
    let { initHeight } = data;
    data.height = initHeight + (e.clientY - this.disY) / this.scaleRatio;
  },
  se: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
    }
    let { initHeight, initWidth } = data;
    data.height = initHeight + (e.clientY - this.disY) / this.scaleRatio;
    data.width = initWidth + (e.clientX - this.disX) / this.scaleRatio;
  },
  sw: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
    }
    let { initHeight, initX, initWidth } = data;
    data.height = initHeight + (e.clientY - this.disY) / this.scaleRatio;
    data.left = initX + (e.clientX - this.disX) / this.scaleRatio;
    data.width = initWidth + (this.disX - e.clientX) / this.scaleRatio;
  },
  n: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
    }
    let { initY, initHeight } = data;
    data.top = initY + (e.clientY - this.disY) / this.scaleRatio;
    data.height = initHeight + (this.disY - e.clientY) / this.scaleRatio;
  },
  ne: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
    }
    let { initY, initHeight, initWidth } = data;
    data.top = initY + e.clientY - this.disY;
    data.height = initHeight + (this.disY - e.clientY) / this.scaleRatio;
    data.width = initWidth + (e.clientX - this.disX) / this.scaleRatio;
  },
  nw: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
    }
    let { initY, initHeight, initX, initWidth } = data;
    data.left = initX + (e.clientX - this.disX) / this.scaleRatio;
    data.width = initWidth + (this.disX - e.clientX) / this.scaleRatio;
    data.top = initY + (e.clientY - this.disY) / this.scaleRatio;
    data.height = initHeight + (this.disY - e.clientY) / this.scaleRatio;
  },
  w: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
    }
    let { initX, initWidth } = data;
    data.left = initX + (e.clientX - this.disX) / this.scaleRatio;
    data.width = initWidth + (this.disX - e.clientX) / this.scaleRatio;
  },
  e: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
    }
    let { initWidth } = data;
    data.width = initWidth + (e.clientX - this.disX) / this.scaleRatio;
  },
};
// 裁切框锚点拉动鼠标抬起事件
const pointMouseUp = {
  s: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
      let item = correctCoverSize.bind(this)(index);
      this.coverList.splice(index, 1, item);
      this.$forceUpdate();
    }
    let { height } = data;
    data.initHeight = height;
    this.$refs.container.onmousemove = function () {};
    this.$refs.container.onmouseup = function () {};
    sizeChangeSubmit.bind(this)();
    this.$forceUpdate();
  },
  se: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
      let item = correctCoverSize.bind(this)(index);
      this.coverList.splice(index, 1, item);
      this.$forceUpdate();
    }
    let { width, height } = data;
    data.initHeight = height;
    data.initWidth = width;
    this.$refs.container.onmousemove = null;
    this.$refs.container.onmouseup = null;
    sizeChangeSubmit.bind(this)();
  },
  sw: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
      let item = correctCoverSize.bind(this)(index);
      this.coverList.splice(index, 1, item);
      this.$forceUpdate();
    }
    let { height, left, width } = data;
    data.initHeight = height;
    data.initX = left;
    data.initWidth = width;
    this.$refs.container.onmousemove = null;
    this.$refs.container.onmouseup = null;
    sizeChangeSubmit.bind(this)();
  },
  n: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
      let item = correctCoverSize.bind(this)(index);
      this.coverList.splice(index, 1, item);
      this.$forceUpdate();
    }
    let { top, height } = data;
    data.initY = top;
    data.initHeight = height;
    this.$refs.container.onmousemove = null;
    this.$refs.container.onmouseup = null;
    sizeChangeSubmit.bind(this)();
  },
  ne: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
      let item = correctCoverSize.bind(this)(index);
      this.coverList.splice(index, 1, item);
      this.$forceUpdate();
    }
    let { top, height, width } = data;
    data.initY = top;
    data.initHeight = height;
    data.initWidth = width;
    this.$refs.container.onmousemove = null;
    this.$refs.container.onmouseup = null;
    sizeChangeSubmit.bind(this)();
  },
  nw: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
      let item = correctCoverSize.bind(this)(index);
      this.coverList.splice(index, 1, item);
      this.$forceUpdate();
    }
    let { top, height, left, width } = data;
    data.initX = left;
    data.initWidth = width;
    data.initY = top;
    data.initHeight = height;
    this.$refs.container.onmousemove = null;
    this.$refs.container.onmouseup = null;
    sizeChangeSubmit.bind(this)();
  },
  w: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
      let item = correctCoverSize.bind(this)(index);
      this.coverList.splice(index, 1, item);
      this.$forceUpdate();
    }
    let { left, width } = data;
    data.initX = left;
    data.initWidth = width;
    this.$refs.container.onmousemove = null;
    this.$refs.container.onmouseup = null;
    sizeChangeSubmit.bind(this)();
  },
  e: function (e, index) {
    let data = this.rectList[index];
    if (this.status === 3) {
      data = this.coverList[index];
      let item = correctCoverSize.bind(this)(index);
      this.coverList.splice(index, 1, item);
      this.$forceUpdate();
    }
    let { width } = data;
    data.initWidth = width;
    this.$refs.container.onmousemove = null;
    this.$refs.container.onmouseup = null;
    sizeChangeSubmit.bind(this)();
  },
};
// 每次抬起鼠标更新切图 并请求接口
const sizeChangeSubmit = function () {
  if (this.status != 3) {
    this.sizeFlag = true;
    if (this.$parent.itemData) {
      const { isMaterial, materialId } = this.$parent.itemData;
      if (isMaterial == 1) {
        if (this.$parent.itemData.isSub) {
          this.type = "1";
          this.form.isMaterial = 1;
        } else {
          if (this.$parent.itData) {
            this.type = "1"; // ok
          } else {
            this.type = "2";
          }
        }
        this.materialForm.materialId = materialId;
      } else {
        this.type = "1";
      }
      this.cutSave();
    }
  }
};
// 校正遮挡区域
export const correctCoverSize = function (index) {
  var coverObj = null;
  let { width, height, top, left } = this.rectList[this.rectIndex];
  let [north, south, west, east] = [top, top + height, left, left + width];
  if (index || index === 0) {
    coverObj = {
      north: this.coverList[index].top,
      south: this.coverList[index].top + this.coverList[index].height,
      west: this.coverList[index].left,
      east: this.coverList[index].left + this.coverList[index].width,
    };
  } else {
    coverObj = {
      north: this.rectObj.top,
      south: this.rectObj.top + this.rectObj.height,
      west: this.rectObj.left,
      east: this.rectObj.left + this.rectObj.width,
    };
  }

  if (
    coverObj.north > south ||
    coverObj.west > east ||
    coverObj.east < west ||
    coverObj.south < north
  ) {
    Message({
      showClose: true,
      message: "请在裁剪区域内长按鼠标左键框选需要遮挡的内容！",
      type: "warning",
    });
    return false;
  } else {
    let data = {};
    data.left = coverObj.west < west ? west : coverObj.west;
    data.top = coverObj.north < north ? north : coverObj.north;
    data.width =
      coverObj.east > east ? east - data.left : coverObj.east - data.left;
    data.height =
      coverObj.south > south ? south - data.top : coverObj.south - data.top;
    data.initHeight = data.height;
    data.initWidth = data.width;
    data.initX = data.left;
    data.initY = data.top;
    return data;
  }
};
