<template>
  <!-- develop -->
  <div v-loading="loading" class="ExamNewPaperCropper">
    <div class="breadCrumb">
      <div class="routerList">
        <i class="el-icon-arrow-left" @click="routerBack"></i>
        <span>{{ $route.query.name }}</span>
        <span>/</span>
        <span>试卷裁切</span>
      </div>
      <div class="btns">
        <el-button
          type="primary"
          :loading="downloadBtn"
          size="mini"
          @click="downLoadgetexampaperzip"
        >
          <i class="el-icon-download"></i> 下载原卷
        </el-button>
        <el-button
          type="primary"
          size="mini"
          :loading="btnLoading"
          @click="achive"
          >完成</el-button
        >
      </div>
    </div>
    <div class="content">
      <div class="operate">
        <div class="paper">
          <paperTool
            ref="paperTool"
            :data="picItem"
            :page="page"
            :source-area="sourceArea"
            :question-type-obj="questionTypeObj"
            :material-list="materialList"
            :exam-question-v-o-list="examQuestionVOList"
            @submit="paperToolSubmit"
            @statusChange="statusChange"
            @getQuestionType="getQuestionType"
          />
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              small
              :total="total"
              :disabled="disablePagination"
              :page-size="1"
              :current-page="page"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
        <div :key="tokenKey" class="list">
          <div class="tool">
            <el-checkbox
              v-if="examQuestionVOList.length"
              v-model="openAll"
              @change="extendChange"
              >展开全部</el-checkbox
            >
            <clearAllQuestions @getData="getNewData" />
          </div>
          <SlickList
            v-if="examQuestionVOList.length"
            v-model="examQuestionVOList"
            :press-threshold="50"
            :lock-to-container-edges="true"
            class="list-box"
            lock-axis="y"
            :delay="1000"
            :distance="10"
            @sort-end="listSortEnd"
            @sort-start="listSortStart"
          >
            <SlickItem
              v-for="(item, index) in examQuestionVOList"
              :key="index"
              ref="listItem"
              :index="index"
              class="list-item"
            >
              <div v-loading="item.loading" class="questionNum">
                <div v-if="item.isMaterial == 1" class="flex">
                  <div class="isMaterial">材料题</div>
                  <div class="order">
                    <span>{{ item.order }}</span>
                  </div>
                </div>
                <div v-else class="flex">
                  <div class="question">试题</div>
                  <div class="order">
                    <span>{{ item.order }}</span>
                  </div>
                </div>

                <!-- <i class="el-icon-edit" @click="edit(item, index)"></i> -->
                <el-button
                  type="text"
                  style="padding: 6px 0"
                  @click.stop="editOrder(item)"
                  >修改题号</el-button
                >
              </div>
              <div class="item-operate">
                <div class="item-operate-item">
                  科目
                  <el-select
                    v-model="item.subjectId"
                    style="width: 146px; margin-left: 8px"
                    placeholder="请选择"
                    @change="selectChange(item, 'subject')"
                  >
                    <el-option
                      v-for="(it, ind) in subjectOptions"
                      :key="ind"
                      :label="it.label"
                      :value="it.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="item-operate-item">
                  题型
                  <el-select
                    :key="Math.random()"
                    v-model="item.type"
                    style="width: 146px; margin-left: 8px"
                    placeholder="请选择"
                    @change="selectChange(item)"
                  >
                    <el-option
                      v-for="(it, ind) in item.questionType"
                      :key="ind"
                      :label="it.name"
                      :value="it.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="btn-box">
                <el-button
                  v-show="!item.show"
                  type="text"
                  style="margin-right: 18px"
                  @click.stop="openItem(item, index)"
                >
                  展开<i class="el-icon-arrow-down"></i>
                </el-button>
                <el-button
                  v-show="item.show"
                  type="text"
                  style="margin-right: 18px"
                  @click.stop="item.show = false"
                  >收起<i class="el-icon-arrow-up"></i
                ></el-button>
                <el-button type="text" class="delete" @click.stop="del(item)"
                  >删除</el-button
                >
              </div>
              <div
                v-show="item.show"
                ref="question"
                class="item-content"
                :style="{
                  borderBottom: item.subQuestions
                    ? '1px solid #d5d6db'
                    : 'none',
                }"
              >
                <div class="html" v-html="item.question"></div>
                <div class="reset-btn" @click.stop="reset(item, index)">
                  重新裁切
                </div>
              </div>
              <div class="subContent">
                <template v-if="item.subQuestions && item.subQuestions.length">
                  <div
                    v-for="(it, ind) in item.subQuestions"
                    :key="ind"
                    class="sub-quetions"
                  >
                    <div class="sub-item">
                      <div class="sub-order">{{ it.order }}</div>
                      <div class="sub-btns">
                        <el-button type="text" @click="editOrder(item, it)"
                          >修改题号</el-button
                        >
                        <el-button
                          v-if="!it.show"
                          type="text"
                          style="margin-right: 18px"
                          @click="subExtend(it, index, ind)"
                        >
                          展开<i class="el-icon-arrow-down"></i>
                        </el-button>
                        <el-button
                          v-else
                          type="text"
                          style="margin-right: 18px"
                          @click="it.show = false"
                          >收起<i class="el-icon-arrow-up"></i
                        ></el-button>
                        <el-button
                          type="text"
                          class="delete"
                          @click="subDel(item, it)"
                          >删除</el-button
                        >
                      </div>
                    </div>
                    <div v-show="it.show" class="sub-content">
                      <div
                        ref="subQuestion"
                        class="sub-html"
                        v-html="it.question"
                      ></div>
                      <div
                        class="reset-btn"
                        @click="reset(item, index, it, ind)"
                      >
                        重新裁切
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="item.isMaterial == 1">
                  <div class="err-tip">
                    当前划题类型为材料题，尚未裁切材料小题！
                  </div>
                </template>
              </div>
            </SlickItem>
          </SlickList>
          <div v-else class="no-data">
            <div class="title">操 作 指 引</div>
            <div class="text">
              <img
                src="@/assets/imgs/pointer.png"
                style="width: 15px; height: 13px"
                alt=""
              />
              在左侧试卷区域内长按鼠标左键框选需要裁切的试题。
            </div>
            <div class="text">
              <img
                src="@/assets/imgs/pointer.png"
                style="width: 15px; height: 13px"
                alt=""
              />
              鼠标拖动裁剪框的矩形按钮
              <div class="block"></div>
              可调整大小。
            </div>
            <div class="text">
              <img
                src="@/assets/imgs/pointer.png"
                style="width: 15px; height: 13px"
                alt=""
              />
              鼠标拖动试题裁剪框中心区域可移动位置。
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="修改题号"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        label-position="right"
        :rules="rules"
        :model="orderForm"
        label-width="80px"
      >
        <el-form-item label="题号" prop="order">
          <el-input
            v-model="orderForm.order"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle">取 消</el-button>
        <el-button type="primary" @click="saveOrder">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import paperTool from "./component/paperTool.vue";
import clearAllQuestions from "./component/clearAllQuestions";
import {
  getExampaperquestionV2,
  getExamQuestionTypeByLevelAndSubject,
  saveCut,
  deleteCut,
  saveMaterial,
  finishexampaperdivide,
  getexampaperzip,
  getExamQuestionTypeByLevel,
  changepapermaxwidth,
} from "@/core/api/exam/exampaper";
// import { fileDownloadByUrl } from "@/core/util/util";
import { filterSubject } from "@/core/util/util";
import { getSubjectList } from "@/core/util/util";
import { SlickList, SlickItem } from "vue-slicksort";
export default {
  components: {
    paperTool,
    SlickItem,
    SlickList,
    clearAllQuestions,
  },
  data() {
    const questionOrderValidate = (rule, value, callback) => {
      if (value) {
        let reg = new RegExp("[;；，,]");
        if (reg.test(value)) {
          callback(new Error("题号禁止包含;；，,"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请输入题号"));
      }
    };
    return {
      rules: {
        order: [
          { required: true, validator: questionOrderValidate, trigger: "blur" },
        ],
      },
      orderForm: {
        order: "",
      },
      form: {},
      subjectOptions: [],
      value: "",
      options: [],
      examPaper: null,
      examQuestionVOList: [],
      subjectName: null,
      resultPath: [],
      page: 1,
      picItem: "",
      total: 0,
      questionTypeObj: [],
      paperForm: {},
      editIndex: 0,
      openAll: true,
      sourceArea: "",
      dialogVisible: false,
      order: "",
      itemData: null,
      itData: null,
      materialList: [],
      loading: false,
      type: null,
      btnLoading: false,
      downloadBtn: false,
      degTotal: 0,
      oldIndex: null,
      newIndex: null,
      allSubjectsQuestionType: [], // 所有主要科目下所有题型
      examPaperCache: [], // 试卷列表临时缓存
      disablePagination: false,
      oldPage: null, // 每次翻页保留上一次页码
      maxWidth: 0,
      tokenKey: Math.random(),
      indexObj: {
        index: null,
        ind: null,
      },
    };
  },
  watch: {
    openAll: {
      handler(val) {
        this.examQuestionVOList.forEach((item) => {
          this.$set(item, "show", val);
          if (item.subQuestions) {
            item.subQuestions.forEach((it) => {
              this.$set(it, "show", val);
            });
          }
        });
      },
    },
  },
  mounted() {
    this.getPaperMsg("upDataPpaer");
  },
  methods: {
    deleteAll() {},
    cancle() {
      this.dialogVisible = false;
      this.$refs.paperTool.exit();
    },
    statusChange(val) {
      if (val == 3) {
        this.disablePagination = true;
      } else {
        this.disablePagination = false;
      }
    },
    async downLoadgetexampaperzip() {
      this.downloadBtn = true;
      try {
        const res = await getexampaperzip(this.$route.query.id);
        // fileDownloadByUrl(res.data.data.url, res.data.data.name);
        let index = res.data.data.name.lastIndexOf(".");
        const suffix = res.data.data.name.substring(index + 1);
        const name = res.data.data.name.substring(0, index);
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: res.data.data.url,
            name:
              name +
              "(" +
              this.getSubjectName(this.$route.query.subjectId) +
              ")原卷." +
              suffix,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.downloadBtn = false;
      } catch {
        this.downloadBtn = false;
      }
    },
    extendChange(val) {
      this.examQuestionVOList.forEach((item) => {
        this.$set(item, "show", val);
        // this.formatteSize(index);
      });
    },
    achive() {
      this.btnLoading = true;
      finishexampaperdivide({ examPaperId: this.$route.query.id })
        .then(() => {
          this.btnLoading = false;
          this.$message({
            showClose: true,
            message: "操作成功",
            type: "success",
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    listSortEnd(val) {
      const { newIndex, oldIndex } = val;
      this.newIndex = newIndex;
      this.oldIndex = oldIndex;
      this.changeList();
    },
    listSortStart() {
      this.openAll = false;
    },

    changeList() {
      let item = this.examQuestionVOList[this.oldIndex];
      if (item.isMaterial === 0) {
        let form = {
          examPaperId: item.examPaperId,
          isMaterial: 0,
          order: item.order,
          type: item.type,
          id: item.id,
          sourceArea: item.sourceArea,
          subjectId: item.subjectId,
          sequence: this.newIndex + 1,
        };
        saveCut(form)
          .then(() => {
            // this.getPaperMsg();
            this.itemData = this.itData = null;
            this.$nextTick(() => {
              this.formatteSize();
            });
            this.openAll = true;
          })
          .catch(() => {
            this.openAll = true;
            this.$refs.paperTool.exit();
            this.itemData = this.itData = null;
          });
      } else if (item.isMaterial === 1) {
        let form = {
          examPaperId: item.examPaperId,
          materialId: item.materialId,
          isMaterial: 1,
          order: item.order,
          id: item.id,
          type: item.type,
          sourceArea: item.sourceArea,
          subjectId: item.subjectId,
          sequence: this.newIndex + 1,
        };
        saveMaterial(form)
          .then(() => {
            // this.getPaperMsg();
            this.itemData = this.itData = null;
            // this.tokenKey = Math.random();
            this.$nextTick(() => {
              this.formatteSize();
              this.openAll = true;
            });
          })
          .catch(() => {
            this.$refs.paperTool.exit();
            this.openAll = true;
            this.itemData = this.itData = null;
          });
      }
      // this.getPaperMsg();
    },
    paperToolSubmit(val) {
      if (val) {
        this.getPaperMsg("upDataPpaer");
      } else {
        this.getPaperMsg();
      }
    },
    subExtend(it) {
      this.$set(it, "show", true);
      // this.formatteSize(index, ind);
    },
    handleClose() {
      this.$refs.paperTool.exit();
      this.dialogVisible = false;
    },
    saveOrder() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogVisible = false;
          if (this.itemData.isMaterial === 1) {
            if (this.itData) {
              this.type = "1";
              this.form = {
                id: this.itData.id,
                examPaperId: this.$route.query.id,
                order: this.itemData.order,
                subOrder: this.orderForm.order,
                subjectId: this.itemData.subjectId,
                type: this.itemData.type,
                isMaterial: this.itemData.isMaterial,
                materialId: this.itemData.materialId,
                sourceArea: this.itemData.sourceArea,
                subSourceArea: this.itData.sourceArea,
              };
            } else {
              this.type = "2";

              this.form = {
                order: this.orderForm.order,
                materialId: this.itemData.materialId,
                examPaperId: this.itemData.examPaperId,
                sourceArea: this.itemData.sourceArea,
                subjectId: this.itemData.subjectId,
                type: this.itemData.type,
                sequence: this.itemData.sequence,
              };
            }
          } else {
            this.type = "1";
            this.form = {
              id: this.itemData.id,
              examPaperId: this.$route.query.id,
              order: this.orderForm.order,
              subjectId: this.itemData.subjectId,
              type: this.itemData.type,
              isMaterial: this.itemData.isMaterial,
              sourceArea: this.itemData.sourceArea,
            };
          }
          this.cutSave();
        }
      });
    },
    editOrder(item, it) {
      this.orderForm.order = item.order;
      this.itemData = { ...item };
      if (it) {
        this.itData = { ...it };
        this.orderForm.order = it.order;
      }
      this.dialogVisible = true;
    },
    cutSave() {
      this.btnLoading = true;
      if (this.type === "1") {
        this.loading = true;
        saveCut({ ...this.form })
          .then(() => {
            this.$message({
              showClose: true,
              message: "操作成功",
              type: "success",
            });
            this.btnLoading = false;

            this.getPaperMsg();

            this.loading = false;
            this.$refs.paperTool.exit();
            this.itemData = null;
            this.itData = null;
            this.orderForm.order = "";
          })
          .catch(() => {
            this.btnLoading = false;
            this.loading = false;
            this.$refs.paperTool.exit();
            this.itemData = null;
            this.itData = null;
            this.orderForm.order = "";
          });
      } else if (this.type === "2") {
        this.loading = true;
        saveMaterial({ ...this.form })
          .then(() => {
            this.loading = false;
            this.btnLoading = false;
            this.$message({
              showClose: true,
              message: "操作成功",
              type: "success",
            });

            this.getPaperMsg();
            this.$refs.paperTool.exit();
            this.itemData = null;
            this.itData = null;
            this.orderForm.order = "";
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.btnLoading = false;
            this.type = null;
            this.$refs.paperTool.exit();
            this.itemData = null;
            this.itData = null;
            this.orderForm.order = "";
          });
      }
    },
    getSubjectName(id) {
      let subjectList = getSubjectList();
      let data = subjectList.find((item) => item.value == id);
      return data.label;
    },
    routerBack() {
      this.$router.go(-1);
    },
    del(item) {
      this.$confirm("此操作将永久删除该题目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          deleteCut({
            examPaperId: item.examPaperId,
            questionOrder: item.order,
          }).then(() => {
            this.loading = false;
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getPaperMsg();
            this.$refs.paperTool.exit();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    reset(item, index, it, ind) {
      this.itemData = { ...item };
      this.indexObj = {
        index: index,
        ind: ind,
      };
      this.$refs.paperTool.initPaperToolEvent();
      let itemSourceArea = JSON.parse(item.sourceArea);
      let page = itemSourceArea[0].priority;
      if (it) {
        itemSourceArea = JSON.parse(it.sourceArea);
        page = itemSourceArea[0].priority;
        this.itemData.subOrder = it.order;
        this.sourceArea = it.sourceArea;
        this.itemData.isSub = true;
        this.itData = { ...it };
      } else {
        this.sourceArea = item.sourceArea;
      }
      this.loading = true;
      this.handleCurrentChange(page);
      this.$refs.paperTool.status = 2;
    },
    updateItemData() {
      if (this.$refs.paperTool.status === 2) {
        const { index, ind } = this.indexObj;
        if (index !== null && index !== undefined) {
          this.itemData = this.examQuestionVOList[index];
          this.sourceArea = this.itemData.sourceArea;
          if (ind !== null && ind !== undefined) {
            this.itData = this.examQuestionVOList[index].subQuestions[ind];
            this.sourceArea = this.itData.sourceArea;
          }
        }
      }
    },
    init() {
      this.sourceArea = "";
      this.itemData = null;
      this.itData = null;
    },
    subDel(item, it) {
      this.$confirm("此操作将永久删除该题目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteCut({
            examPaperId: item.examPaperId,
            questionOrder: item.order,
            subQuestionOrder: it.order,
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getPaperMsg();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    selectChange(item, type) {
      let subjectObj = this.allSubjectsQuestionType.find(
        (it) => it.subjectId === item.subjectId
      );
      this.$set(item, "questionType", subjectObj.questionType);
      if (type === "subject") {
        this.$set(item, "type", subjectObj.questionType[0].id);
      }
      if (item.isMaterial === 1) {
        this.form = {
          order: item.order,
          examPaperId: item.examPaperId,
          materialId: item.materialId,
          sourceArea: item.sourceArea,
          type: type === "subject" ? subjectObj.questionType[0].id : item.type,
          subjectId: item.subjectId,
        };
        this.type = "2";
      } else {
        this.type = "1";
        this.form = {
          id: item.id,
          examPaperId: this.$route.query.id,
          order: item.order,
          subjectId: item.subjectId,
          type: type === "subject" ? subjectObj.questionType[0].id : item.type,
          isMaterial: item.isMaterial,
          sourceArea: item.sourceArea,
        };
      }

      this.cutSave();
    },

    edit(item, index) {
      this.$refs.paperTool.edit(item, index);
    },
    async getQuestionType(subjectId) {
      const { level } = this.examPaper;
      const formData = new FormData();
      formData.append("level", level);
      formData.append("subjectId", subjectId);
      const res = await getExamQuestionTypeByLevelAndSubject(formData);
      this.questionTypeObj = res.data.data.resQuestionTypes;
    },
    async getSubjectQuestionType() {
      const { subjectId, level } = this.examPaper;
      const formData = new FormData();
      formData.append("level", level);
      formData.append("subjectId", subjectId);
      const res = await getExamQuestionTypeByLevelAndSubject(formData);
      this.questionTypeObj = res.data.data.resQuestionTypes;
    },
    updataPaper() {
      // 更新试卷图片
      this.examPaper = JSON.parse(JSON.stringify(this.examPaperCache));
    },
    getNewData() {
      this.getPaperMsg();
    },
    async getPaperMsg(val) {
      // this.picItem = null;
      this.loading = true;
      // this.resultPath = [];
      const res = await getExampaperquestionV2({
        examPaperId: this.$route.query.id,
      });
      this.examPaperCache = res.data.data.examPaper;
      if (val === "upDataPpaer") {
        this.picItem = null;
        this.resultPath = [];
        this.updataPaper();
      }
      this.getSubjectQuestionType();
      this.examQuestionVOList = res.data.data.examQuestionVOList;
      if (this.indexObj.index !== null) {
        this.updateItemData();
      }
      const { level } = this.examPaper;
      const result = await getExamQuestionTypeByLevel({ level: level });
      const allSubject = result.data.data.map((item) => item.subjectId);
      const subjects = [...new Set(allSubject)];
      this.allSubjectsQuestionType = subjects.map((item) => {
        let obj = {
          subjectId: item,
          questionType: [],
        };
        result.data.data.map((it) => {
          if (item === it.subjectId) {
            obj.questionType.push(it);
          }
        });
        return obj;
      });
      this.examQuestionVOList.forEach(async (item) => {
        const subjectObj = this.allSubjectsQuestionType.find(
          (it) => it.subjectId === item.subjectId
        );
        this.$set(item, "questionType", subjectObj.questionType);
        this.$set(item, "loading", false);
        this.$set(item, "show", this.openAll);
        if (item.subQuestions) {
          item.subQuestions.forEach((it) => {
            this.$set(it, "show", this.openAll);
          });
        }
      });

      const subjectList = getSubjectList();
      this.subjectOptions = [...filterSubject(this.examPaper.level)];
      this.materialList = res.data.data.examQuestionVOList.filter(
        (item) => item.isMaterial == 1
      );
      if (val && val !== "upDataPpaer") {
        const result = this.materialList.some(
          (item) => item.order === val.order
        );
        if (!result) {
          this.materialList.push(val);
        }
      }
      if (val === "upDataPpaer") {
        this.resultPath = this.examPaper.resultPath
          .split(",")
          .map((item) => "https://static.wtjy.com/" + item);
        this.total = this.resultPath.length;
        if (this.total) {
          this.picItem =
            this.resultPath[this.page - 1] + `?${new Date().getTime()}`;
        }
      }
      this.subjectName = subjectList.find(
        (item) => item.subjectId === this.examPaper.subjectId
      ).subjectName;
      this.setMaxWidth();
      this.loading = false;
      setTimeout(() => {
        if (this.$refs.listItem[this.indexObj.index]) {
          this.$refs.listItem[this.indexObj.index].$el.scrollIntoView();
        } else {
          let ele = document.getElementsByClassName("list-box")[0];
          ele.scrollTop = ele.scrollHeight;
        }
      }, 500);
    },
    openItem(item) {
      item.show = true;
      this.$set(item, "show", true);
      // this.formatteSize(index);
    },
    formatteSize() {
      this.tokenKey = Math.random();
      this.$nextTick(() => {
        var jzjxImg = document.getElementsByClassName("jzjxImg");
        let jzjxImgArr = [...jzjxImg];
        let maxWidth = 398,
          ratio = 1;
        if (jzjxImgArr.length) {
          if (jzjxImgArr[0].style.maxWidth) {
            maxWidth = this.removePxToNumber(jzjxImgArr[0].style.maxWidth);
          }
          let width = this.removePxToNumber(jzjxImgArr[0].style.width);
          if (maxWidth < width) {
            maxWidth = width;
          }
          ratio = 398 / maxWidth;
        }
        for (let item of jzjxImg) {
          const width = this.removePxToNumber(item.style.width);
          const height = this.removePxToNumber(item.style.height);
          this.$set(item.style, "width", width * ratio + "px");
          this.$set(item.style, "height", height * ratio + "px");
          this.$set(item.style, "display", "block");
          this.$forceUpdate();
        }
      });
    },
    removePxToNumber(val) {
      let data = val.replace("px", "");
      return Number(data);
    },
    resetSize(val) {
      let imgObj = this.$refs.paperTool.imgObj;
      const ratio = imgObj.width / imgObj.naturalWidth;
      const data = val / ratio;
      return data;
    },
    handleCurrentChange(val) {
      this.loading = true;
      let paperTool = this.$refs.paperTool;
      const statusBoolean =
        (paperTool.status === 2 && !paperTool.appendFlag) ||
        paperTool.status === 3;
      const booleanValue = [statusBoolean, !this.itemData, !this.itData];
      const result = booleanValue.every((item) => item);
      if (result) {
        this.$confirm("是否保存当前裁切试题？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$refs.paperTool.cutQuestion(val);
            this.$refs.paperTool.pageChange = true;
          })
          .catch(() => {
            this.$refs.paperTool.status = 1;
            // this.picItem =
            //   this.resultPath[val - 1] + `?${new Date().getTime()}`;
            this.page = val;
            this.picItem =
              this.resultPath[this.page - 1] + `?${new Date().getTime()}`;
            this.$refs.paperTool.$refs.paperBox.scrollTo(0, 0);
            this.$refs.paperTool.bindMouseToPaperTool();
            this.$refs.paperTool.exit();
            this.sourceArea = "";
            this.loading = false;
          });
      } else {
        this.picItem = this.resultPath[val - 1] + `?${new Date().getTime()}`;
        this.page = val;
        this.$refs.paperTool.$refs.paperBox.scrollTo(0, 0);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    getSouceArea(arr) {
      const sourceArea = arr.flatMap((item) => JSON.parse(item.sourceArea));
      return sourceArea;
    },
    setMaxWidth() {
      const widthArr = this.examQuestionVOList.flatMap((item) => {
        let sourceArea = JSON.parse(item.sourceArea);
        let subSourceArea = item.subQuestions
          ? this.getSouceArea(item.subQuestions)
          : [];
        let data = [
          ...sourceArea.map((it) => Math.round(it.width)),
          ...subSourceArea.map((it) => Math.round(it.width)),
        ];
        return data;
      });
      if (widthArr.length) {
        let max = Math.max(...widthArr);
        this.maxWidth = this.maxWidth > max ? this.maxWidth : max;
        changepapermaxwidth({
          maxWidth: this.maxWidth,
          examPaperId: this.$route.query.id,
        });
        this.formatteSize();
      }
    },
    handleClick(index) {
      if (index == 0) {
        this.$refs.paperTool.showFullScreen = true;
      } else if (index == 2) {
        this.$refs.paperTool.scaleAdd();
      } else if (index == 3) {
        this.$refs.paperTool.scaleReduce();
      } else if (index == 4) {
        this.$refs.paperTool.rotateAdd();
      } else if (index == 5) {
        this.$refs.paperTool.rotateReduce();
      } else if (index == 7) {
        this.$refs.paperTool.reset();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ExamNewPaperCropper {
  box-sizing: border-box;
  padding: 18px 0;
  min-height: 100vh;
  overflow: hidden;
  .breadCrumb {
    background-color: #fff;
    box-sizing: border-box;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    color: #101011;
    line-height: 1;
    justify-content: space-between;
    .routerList {
      display: flex;
      align-items: center;
      i {
        margin-right: 8px;
        cursor: pointer;
      }
      span {
        margin: 0 8px;
      }
    }
    .btns {
    }
  }
  .content {
    background-color: #fff;
    margin: 18px 0;
    font-size: 14px;
    color: #101011;
    box-sizing: border-box;
    padding-right: 18px;
    .step-line {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        .circle {
          text-align: center;
          width: 24px;
          height: 24px;
          background: #ffffff;
          color: #5f5f68;
          border: 1px solid #5f5f68;
          line-height: 24px;
          border-radius: 50%;
          margin-bottom: 18px;
        }
        .text {
          text-align: center;
          color: #5f5f68;
        }
      }
      .active {
        display: flex;
        flex-direction: column;
        width: fit-content;
        align-items: center;
        .circle {
          text-align: center;
          width: 24px;
          height: 24px;
          background: #2474ed;
          border: 1px solid #2474ed;
          color: #fff;
          line-height: 24px;
          border-radius: 50%;
          margin-bottom: 18px;
        }
        .text {
          text-align: center;
          color: #2474ed;
        }
      }
      .line {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          width: 326px;
          height: 1;
          border-top: 1px dashed #5f5f68;
          top: 12px;
          left: -326px;
        }
        &::after {
          position: absolute;
          content: "";
          width: 326px;
          height: 1;
          border-top: 1px dashed #5f5f68;
          top: 12px;
          right: -326px;
        }
      }
    }
    .setting {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      justify-content: space-between;
      .form {
        display: flex;
        align-items: center;
        .row {
          display: flex;
          align-items: center;
          margin-right: 24px;

          .el-input {
            width: 80px;
          }
        }
      }
    }
    .operate {
      display: flex;
      align-items: flex-start;
      .paper {
        width: calc(100% - 462px);
        position: relative;
        box-sizing: border-box;
        padding-bottom: 42px;
        .pagination {
          position: absolute;
          bottom: 0;
          right: 60px;
        }
      }
      .list {
        width: 462px;
        height: 688px;
        background: #f2f3f5;
        box-sizing: border-box;
        padding: 0 10px;
        .tool {
          display: flex;
          align-items: center;
        }

        .list-box {
          height: calc(688px - 40px - 0.5rem);
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 8px;
            height: 3px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #dee1e7;
            border-radius: 20px;
          }
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px #e8e8e8;
            background-color: #fafafa;
            border: 1px solid #e8e8e8;
          }
        }
        .no-data {
          height: calc(688px - 20px);
          background-color: #fff;
          padding: 0 60px;
          padding-top: 35%;
          box-sizing: border-box;
          .title {
            font-size: 18px;
            color: #1e80f3;
            margin: 0 auto;
            margin-bottom: 32px;
            text-align: center;
            font-weight: bold;
            position: relative;
            width: fit-content;
            &::before {
              content: "";
              position: absolute;
              left: -52px;
              width: 44px;
              height: 14px;
              background-image: url("~@/assets/imgs/point_title_left.png");
              background-size: 100% 100%;
              top: 50%;
              transform: translateY(-50%);
            }
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -52px;
              width: 44px;
              height: 14px;
              background-image: url("~@/assets/imgs/point_title_right.png");
              background-size: 100% 100%;
            }
          }
          .text {
            display: flex;
            align-items: flex-start;
            text-align: left;
            font-size: 14px;
            color: #101011;
            margin-bottom: 32px;
            line-height: 1.5;
            img {
              margin: 5px 5px 5px 0;
            }
          }
        }
      }
    }
  }
}
.inline {
  display: flex;
  align-items: center;
  .label {
    margin-right: 8px;
    white-space: nowrap;
  }
}
.list-item {
  min-height: 144px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 18px;
  margin-bottom: 8px;
  .questionNum {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      color: #101011;
      font-size: 14px;
      line-height: 26px;
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        // background: #f4f8ff;
        // box-sizing: border-box;
        // padding: 4px;
      }
    }
  }
  .item-operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }
  .btn-box {
    display: flex;
    justify-content: flex-end;
    .delete {
      color: #d92020;
      margin: 0;
    }
  }
  .item-content {
    width: 100%;
    position: relative;
    margin: 18px 0;

    .html {
      width: 100%;
      ::v-deep p {
        width: 100%;
        ::v-deep .jzjxImg {
          height: auto !important;
          object-fit: contain;
          display: block;
          max-width: 100%;
        }
      }
    }
    .reset-btn {
      position: absolute;
      right: 0;
      top: 0;
      display: none;
      font-size: 12px;
      color: #fff;
      background-color: rgba(16, 16, 17, 0.4);
      text-align: center;
      height: 32px;
      line-height: 32px;
      box-sizing: border-box;
      padding: 0 5px;
      cursor: pointer;
    }
    &:hover .reset-btn {
      display: block;
    }
  }
  .sub-quetions {
    margin-bottom: 10px;
    .sub-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .sub-order {
        font-size: 14px;
        color: #101011;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: fit-content;
      }
      .sub-btns {
        display: flex;
        align-items: flex-start;
        .delete {
          color: #d92020;
          margin: 0;
        }
      }
    }
    .sub-content {
      width: 100%;
      position: relative;
      margin-top: 18px;
      .sub-html {
        width: 100%;
        ::v-deep p {
          width: 100%;
          img {
            height: auto !important;
            max-width: 100% !important;
          }
        }
      }
      .reset-btn {
        position: absolute;
        right: 0;
        top: 0;
        display: none;
        font-size: 12px;
        color: #fff;
        background-color: rgba(16, 16, 17, 0.4);
        text-align: center;
        height: 32px;
        line-height: 32px;
        box-sizing: border-box;
        padding: 0 5px;
        cursor: pointer;
      }
      &:hover .reset-btn {
        display: block;
      }
    }
  }
}
.block {
  width: 10px;
  height: 10px;
  background-color: #1e80f3;
  align-self: center;
  margin: 0 5px;
}
.err-tip {
  border: 1px dashed #ffb5b4;
  border-radius: 4px;
  background: #fff3f3;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  color: #292626;
  font-size: 14px;
}
.list-item {
  min-height: 144px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 18px;
  margin-bottom: 8px;
  .questionNum {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .isMaterial {
      box-sizing: border-box;
      padding: 2px 6px;
      font-size: 12px;
      background-color: #fff9f4;
      height: 24px;
      color: #f45f3f;
      line-height: 24px;
    }
    .flex {
      display: flex;
      align-items: center;
      .question {
        box-sizing: border-box;
        padding: 2px 6px;
        font-size: 12px;
        background-color: #f4f8ff;
        height: 24px;
        color: #2474ed;
        line-height: 24px;
      }
    }
    .order {
      flex: 1;
      color: #101011;
      font-size: 14px;
      box-sizing: border-box;
      padding: 0 4px;
      user-select: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .item-operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }
  .btn-box {
    display: flex;
    justify-content: flex-end;
    user-select: none;
    margin-bottom: 10px;
    .delete {
      color: #d92020;
      margin: 0;
    }
  }
  .item-content {
    width: 100%;
    position: relative;
    margin: 18px 0;
    user-select: none;

    .html {
      width: 100%;
      user-select: none;
      -webkit-user-drag: none;
      ::v-deep .jzjxImg {
        user-select: none !important;
        -webkit-user-drag: none !important;
        height: auto !important;
        object-fit: contain;
        user-select: none !important;
        -webkit-user-drag: none !important;
        display: block;
        max-width: 100% !important;
      }
    }
    .reset-btn {
      position: absolute;
      right: 0;
      top: 0;
      display: none;
      font-size: 12px;
      color: #fff;
      background-color: rgba(16, 16, 17, 0.4);
      text-align: center;
      height: 32px;
      line-height: 32px;
      box-sizing: border-box;
      padding: 0 5px;
      cursor: pointer;
    }
    &:hover .reset-btn {
      display: block;
    }
  }
  .sub-quetions {
    margin-bottom: 10px;
    .sub-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .sub-order {
        font-size: 14px;
        color: #101011;
        width: fit-content;
      }
      .sub-btns {
        display: flex;
        align-items: flex-start;
        .delete {
          color: #d92020;
          margin: 0;
        }
      }
    }
    .sub-content {
      width: 100%;
      position: relative;
      margin-top: 18px;
      .sub-html {
        width: 100%;
        ::v-deep p {
          width: 100%;
          img {
            // width: 100% !important;
            height: auto !important;
            display: block;
          }
        }
      }
      .reset-btn {
        position: absolute;
        right: 0;
        top: 0;
        display: none;
        font-size: 12px;
        color: #fff;
        background-color: rgba(16, 16, 17, 0.4);
        text-align: center;
        height: 32px;
        line-height: 32px;
        box-sizing: border-box;
        padding: 0 5px;
        cursor: pointer;
      }
      &:hover .reset-btn {
        display: block;
      }
    }
  }
}
</style>
