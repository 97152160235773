<template>
  <div v-loading="loading" class="paperTool-container">
    <div ref="paperBox" class="paper-box">
      <div
        ref="paperTool"
        class="paperTool"
        :style="{
          transform: `scale(${scaleRatio})`,
        }"
      >
        <div
          ref="container"
          class="box"
          :style="{ width: `${imgObj.width}px`, height: `${imgObj.height}px` }"
        >
          <img
            ref="paperImg"
            :src="data"
            class="img"
            :draggable="false"
            alt=""
            @load="imgOnload"
          />
          <canvas
            id="rectRender"
            ref="rectRender"
            width="200px"
            height="200px"
          ></canvas>
          <template v-if="status === 2 || status === 3">
            <svg
              v-if="!appendFlag"
              style="position: absolute; top: 0; left: 0"
              :width="imgObj.width"
              :height="imgObj.height"
            >
              <defs>
                <mask id="myMask">
                  <rect
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    style="stroke: none; fill: #ccc"
                  ></rect>
                  <rect
                    v-for="(item, index) in rectList"
                    v-show="
                      (status === 2 || (status === 3 && index === rectIndex)) &&
                      item.priority == page
                    "
                    :id="'rect' + +index"
                    :key="index"
                    :width="item.width"
                    :height="item.height"
                    :x="item.left"
                    :y="item.top"
                    style="fill: #000"
                  />
                </mask>
              </defs>
              <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                style="
                  stroke: none;
                  fill: rgba(0, 0, 0, 0.6);
                  mask: url(#myMask);
                "
              ></rect>
            </svg>
            <template v-if="status == 2">
              <div
                v-for="(item, index) in rectList"
                v-show="item.priority == page"
                :key="index"
                ref="rectList"
                class="rect"
                :style="{
                  width: item.width + 'px',
                  height: item.height + 'px',
                  top: item.top + 'px',
                  left: item.left + 'px',
                  pointerEvents: pointerEvents,
                }"
                @mousedown.stop="(e) => rectMouseDown(e, index)"
              >
                <div class="rect-wrapper">
                  <div
                    class="rect-point n"
                    @mousedown.stop="(e) => pointMouseDown(e, 'n', index)"
                  ></div>
                  <div
                    class="rect-point nw"
                    @mousedown.stop="(e) => pointMouseDown(e, 'nw', index)"
                  ></div>
                  <div
                    class="rect-point w"
                    @mousedown.stop="(e) => pointMouseDown(e, 'w', index)"
                  ></div>
                  <div
                    class="rect-point sw"
                    @mousedown.stop="(e) => pointMouseDown(e, 'sw', index)"
                  ></div>
                  <div
                    class="rect-point w"
                    @mousedown.stop="(e) => pointMouseDown(e, 'w', index)"
                  ></div>
                  <div
                    class="rect-point s"
                    @mousedown.stop="(e) => pointMouseDown(e, 's', index)"
                  ></div>
                  <div
                    class="rect-point se"
                    @mousedown.stop="(e) => pointMouseDown(e, 'se', index)"
                  ></div>
                  <div
                    class="rect-point e"
                    @mousedown.stop="(e) => pointMouseDown(e, 'e', index)"
                  ></div>
                  <div
                    class="rect-point ne"
                    @mousedown.stop="(e) => pointMouseDown(e, 'ne', index)"
                  ></div>
                  <div
                    v-for="(it, ind) in item.maskList"
                    :key="ind"
                    class="cover-block"
                    :style="{
                      width: it.width + 'px',
                      height: it.height + 'px',
                      top: it.top + 'px',
                      left: it.left + 'px',
                    }"
                  ></div>
                  <div v-if="!appendFlag" ref="rectOperate" class="rectOperate">
                    <template v-if="status === 2">
                      <div
                        v-if="!$parent.itemData"
                        class="rectOperate-item"
                        @click="cutQuestion(item, index)"
                      >
                        裁题
                      </div>
                      <div
                        v-else
                        class="rectOperate-item"
                        @click="exit(item, index)"
                      >
                        退出编辑
                      </div>
                      <div class="rectOperate-item" @click="addCover(index)">
                        添加遮挡
                      </div>
                      <div class="rectOperate-item" @click="appendArea">
                        追加区域
                      </div>
                      <div class="rectOperate-item" @click="del(item, index)">
                        删除
                      </div>
                    </template>
                  </div>
                  <div v-else class="rectOperate">
                    <div class="rectOperate-item" @click="appendArea">
                      追加区域
                    </div>
                    <!-- 追加区域添加遮挡 -->
                    <div class="rectOperate-item" @click="addCover(index)">
                      添加遮挡
                    </div>
                    <div
                      v-if="!$parent.itemData"
                      class="rectOperate-item"
                      @click="cutQuestion(item, index)"
                    >
                      完成
                    </div>

                    <div class="rectOperate-item" @click="exit(item, index)">
                      取消
                    </div>
                    <div class="rectOperate-item" @click="del(item, index)">
                      删除
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="status === 3">
              <div
                v-for="(item, index) in coverList"
                :key="index"
                ref="cover"
                class="cover"
                :style="{
                  width: item.width + 'px',
                  height: item.height + 'px',
                  top: item.top + 'px',
                  left: item.left + 'px',
                  pointerEvents: pointerEvents,
                }"
                @mousedown.stop="(e) => coverMouseDown(e, index)"
              >
                <div class="cover-wrapper">
                  <div
                    class="cover-point n"
                    @mousedown.stop="(e) => pointMouseDown(e, 'n', index)"
                  ></div>
                  <div
                    class="cover-point nw"
                    @mousedown.stop="(e) => pointMouseDown(e, 'nw', index)"
                  ></div>
                  <div
                    class="cover-point ne"
                    @mousedown.stop="(e) => pointMouseDown(e, 'ne', index)"
                  ></div>
                  <div
                    class="cover-point e"
                    @mousedown.stop="(e) => pointMouseDown(e, 'e', index)"
                  ></div>
                  <div
                    class="cover-point se"
                    @mousedown.stop="(e) => pointMouseDown(e, 'se', index)"
                  ></div>
                  <div
                    class="cover-point s"
                    @mousedown.stop="(e) => pointMouseDown(e, 's', index)"
                  ></div>
                  <div
                    class="cover-point sw"
                    @mousedown.stop="(e) => pointMouseDown(e, 'sw', index)"
                  ></div>
                  <div
                    class="cover-point w"
                    @mousedown.stop="(e) => pointMouseDown(e, 'w', index)"
                  ></div>
                </div>
              </div>
            </template>
            <div
              v-if="status === 3"
              class="coverOperate"
              :style="{
                pointerEvents: pointerEvents,

                top:
                  rectList[rectIndex].top + rectList[rectIndex].height + 'px',
                right:
                  imgObj.width -
                  (rectList[rectIndex].left + rectList[rectIndex].width) +
                  'px',
              }"
              @mouseenter="initPaperToolEvent"
              @mouseleave="bindMouseToPaperTool"
            >
              <div class="coverOperate-item" @click="achiveCover">完成遮挡</div>
              <div class="coverOperate-item" @click="clearCover">清空遮挡</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="paper-operate">
      <div
        v-for="(item, index) in operateList"
        :key="index"
        class="paper-operate-item"
        @click="handleClick(index)"
      >
        <i :class="item.icon"></i>
      </div>
    </div>
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="scaleVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-input
        v-if="title === '自定义放大/缩小系数'"
        v-model="scaleNum"
        placeholder="请输入放大倍数"
        oninput="value=value.replace(/[^0-9.]/g,'')"
        @blur="scaleNum = $event.target.value"
      ></el-input>
      <div v-if="title === '划题'" class="cut">
        <el-tabs v-model="type" @tab-click="handleTabClick">
          <el-tab-pane label="试题" :name="'1'">
            <el-form
              ref="form"
              label-position="right"
              label-width="100px"
              :rules="rules"
              :model="form"
            >
              <el-form-item
                v-if="form.isMaterial == 1"
                label="题 号"
                prop="subOrder"
              >
                <el-input
                  v-model="form.subOrder"
                  placeholder="请输入题号"
                  style="width: 340px"
                  maxlength="30"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item v-else label="题 号" prop="order">
                <el-input
                  v-model="form.order"
                  placeholder="请输入题号"
                  maxlength="30"
                  style="width: 340px"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="材料小题" prop="isMaterial">
                <el-switch
                  v-model="form.isMaterial"
                  :active-value="1"
                  :inactive-value="0"
                  @change="switchChange"
                >
                </el-switch>
              </el-form-item>
              <template v-if="form.isMaterial != 1">
                <el-form-item label="科 目" prop="subjectId">
                  <el-select
                    v-model="form.subjectId"
                    placeholder="请选择"
                    @change="subjectIdChange"
                  >
                    <el-option
                      v-for="(it, ind) in subjectOptions"
                      :key="ind"
                      :label="it.label"
                      :value="it.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="题 型" prop="type">
                  <el-select
                    :key="randomKey"
                    v-model="form.type"
                    clearable
                    style="width: 220px"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(it, ind) in questionTypeObj"
                      :key="ind"
                      :label="it.name"
                      :value="it.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
              <el-form-item
                v-if="form.isMaterial == 1"
                prop="order"
                label="所属材料"
              >
                <el-select
                  v-model="form.materialId"
                  placeholder="请选择"
                  @change="materialIdChanage"
                >
                  <el-option
                    v-for="(it, ind) in materialList"
                    :key="ind"
                    :label="it.order"
                    :value="it.materialId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="材料题" :name="'2'">
            <div class="err-tip">
              注：当前划题类型为材料题，裁切材料题后需裁切材料小题，否则组合题号与试卷讲评无法查看裁切试题！
            </div>
            <el-form
              ref="materialForm"
              label-position="right"
              label-width="100"
              :rules="materialFormRules"
              :model="materialForm"
            >
              <el-form-item label="题 号" prop="order">
                <el-input
                  v-model="materialForm.order"
                  style="width: 340px"
                  placeholder="请输入题号"
                  maxlength="30"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="科 目" prop="subjectId">
                <el-select
                  v-model="materialForm.subjectId"
                  placeholder="请选择"
                  @change="subjectIdChange"
                >
                  <el-option
                    v-for="(it, ind) in subjectOptions"
                    :key="ind"
                    :label="it.label"
                    :value="it.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="题 型" prop="type">
                <el-select
                  :key="randomKey"
                  v-model="materialForm.type"
                  clearable
                  style="width: 220px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(it, ind) in questionTypeObj"
                    :key="ind"
                    :label="it.name"
                    :value="it.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle">取 消</el-button>
        <el-button
          v-if="title === '自定义放大/缩小系数'"
          type="primary"
          :loading="btnLoading"
          @click="submitScale"
          >确 定</el-button
        >
        <el-button
          v-if="title === '划题'"
          type="primary"
          :loading="btnLoading"
          @click="formSave"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  operates,
  pointMouseDown,
  correctCoverSize,
} from "./paperToolStrategy";
import { filterSubject } from "@/core/util/util";
import { subjectOptions } from "@/core/util/constdata";
import {
  saveCut,
  saveMaterial,
  deleteCut,
  // changepapermaxwidth,
} from "@/core/api/exam/exampaper";

// import { saveMaterial } from "@/core/api/exam/exampaper";
export default {
  props: {
    data: {
      type: [String, null],
      default: null,
    },
    page: {
      type: Number,
      default: 1,
    },

    sourceArea: {
      type: String,
      default: "",
    },
    examQuestionVOList: {
      type: Array,
      default: () => [],
    },
    materialList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const questionOrderValidate = (rule, value, callback) => {
      if (value) {
        let reg = new RegExp("[;；，,]");
        if (reg.test(value)) {
          callback(new Error("题号禁止包含;；，,"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请输入题号"));
      }
    };
    return {
      btnLoading: false,
      degTotal: 0,
      imgUrl: "",
      rules: {
        order: [
          { required: true, validator: questionOrderValidate, trigger: "blur" },
        ],
        subOrder: [
          { required: true, validator: questionOrderValidate, trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择题型", trigger: "change" }],
        subjectId: [
          { required: true, message: "请选择科目", trigger: "change" },
        ],
      },
      materialFormRules: {
        order: [
          { required: true, validator: questionOrderValidate, trigger: "blur" },
        ],
        subjectId: [
          { required: true, message: "请选择科目", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择题型", trigger: "change" }],
      },
      materialForm: {
        subjectId: null,
        type: null,
        order: null,
      },
      loading: false,
      scaleVisible: false,
      image: null,
      subjectOptions: [],
      operateList: [
        {
          icon: "el-icon-zoom-out",
        },
        {
          icon: "el-icon-zoom-in",
        },
        {
          icon: "el-icon-refresh-left",
        },
        {
          icon: "el-icon-refresh-right",
        },
        // {
        //   icon: "el-icon-setting",
        // },
        // {
        //   icon: "el-icon-refresh",
        // },
      ],
      scaleNum: null,
      scaleRatio: 1,
      rotateDeg: 0,
      x: null,
      y: null,
      rectObj: null,
      imgObj: {},
      itemData: {},
      rectCtx: null,
      status: 1, // 1.准备裁题 2.裁题完成，弹出遮罩和调整框 3.添加遮挡
      appendFlag: false,
      examPaperId: null,
      rectList: [],
      coverList: [],

      disY: 0,
      disX: 0,
      rectIndex: null, // 添加遮挡时 记录其数据下标
      pointerEvents: "inherit",
      title: "",
      type: "1",
      form: {
        subOrder: null,
        type: null,
        subjectId: null,
        order: null,
        isMaterial: 0,
      },
      sizeFlag: false, // 调整截图框尺寸标致
      pageChange: false,
      newPage: null,
      primarySubject: [],
      subject11: [
        ...subjectOptions,
        {
          value: 10,
          label: "科学",
        },
      ],
      questionTypeObj: [],
      randomKey: null,
      ratioObj: {},
    };
  },
  watch: {},
  mounted() {},
  methods: {
    setSourceArea() {
      let arr = this.sourceArea ? JSON.parse(this.sourceArea) : [];
      const list = arr.map((item) => {
        let maskList = item.maskList.map((it) => {
          return {
            height: this.formateSize(it.height, this.ratioObj),
            left: this.formateSize(it.left, this.ratioObj),
            top: this.formateSize(it.top, this.ratioObj),
            width: this.formateSize(it.width, this.ratioObj),
            initY: this.formateSize(it.top, this.ratioObj),
            initHeight: this.formateSize(it.height, this.ratioObj),
            initX: this.formateSize(it.left, this.ratioObj),
            initWidth: this.formateSize(it.width, this.ratioObj),
            ratio: this.ratioObj ? this.ratioObj : 1,
          };
        });
        return {
          width: this.formateSize(item.width, this.ratioObj),
          height: this.formateSize(item.height, this.ratioObj),
          left: this.formateSize(item.left, this.ratioObj),
          top: this.formateSize(item.top, this.ratioObj),
          initY: this.formateSize(item.top, this.ratioObj),
          initHeight: this.formateSize(item.height, this.ratioObj),
          initX: this.formateSize(item.left, this.ratioObj),
          initWidth: this.formateSize(item.width, this.ratioObj),
          priority: item.priority,
          maskList: maskList,
          ratio: this.ratioObj ? this.ratioObj : 1,
        };
      });
      if (list.length) {
        this.rectList = [...list];
      }
    },
    changeRandomkey() {
      this.randomKey = Math.random();
    },
    subjectIdChange(id) {
      const data = this.$parent.allSubjectsQuestionType.find(
        (item) => item.subjectId == id
      );
      this.questionTypeObj = data.questionType;
      if (this.type == "1") {
        this.$set(this.form, "type", "");
      } else if (this.type == "2") {
        this.$set(this.materialForm, "type", "");
      }
    },
    imgOnload() {
      this.renderImg();
      this.$emit("statusChange", 1);
      this.bindMouseToPaperTool();
      this.$parent.loading = false;
    },
    cancle() {
      if (this.type == 1) {
        this.$refs.form.clearValidate();
      } else if (this.type == 2) {
        this.$refs.materialForm.clearValidate();
      }
      this.scaleVisible = false;
      this.appendFlag = false;
      this.status = 1;
      this.$emit("statusChange", 1);
      this.bindMouseToPaperTool();
      this.exit();
      if (this.pageChange) {
        this.$parent.handleCurrentChange(this.newPage);
      }
    },
    materialIdChanage() {
      let data = this.materialList.find(
        (item) => item.materialId === this.form.materialId
      );
      this.form.order = data.order;
    },
    switchChange(val) {
      if (val) {
        this.$nextTick(() => {
          this.$set(this.form, "order", null);
          this.$set(this.form, "subOrder", "");
        });
      } else {
        this.$nextTick(() => {
          this.$set(this.form, "order", "");
          this.$set(this.form, "subOrder", null);
        });
      }
    },
    appendArea() {
      this.status = 2;
      this.$emit("statusChange", 2);
      this.$message({
        showClose: true,
        message: "请框选追加的试题区域！",
        type: "info",
      });
      this.rectObj = null;
      if (this.$parent.itemData) {
        if (this.$parent.itemData.isMaterial === 1) {
          const { isMaterial, materialId } = this.$parent.itemData;
          this.materialForm = {
            examPaperId: this.$route.query.id,
            sourceArea: this.resetRectList(),
            materialId: materialId,
          };
          if (isMaterial === 1) {
            if (this.$parent.itData) {
              this.type = "1"; // ok
            } else {
              this.type = "2";
            }
          }
        } else {
          this.form = { ...this.$parent.itemData };
          this.form.examPaperId = this.$route.query.id;
          let list = this.resetRectList();
          this.form.sourceArea = list;
        }
      }
      this.bindMouseToPaperTool();
      this.appendFlag = true;
    },
    handleTabClick() {
      this.changeRandomkey();
      let result = this.subject11.some(
        (item) => item.value == this.$route.query.subjectId
      );
      if (result) {
        if (this.type == 1) {
          this.$refs.form.clearValidate();
          this.$nextTick(() => {
            if (!this.form.subjectId) {
              this.form.subjectId = Number(this.$route.query.subjectId);
            }
            this.getQuestionTypeObj();
          });
        } else if (this.type == 2) {
          this.$nextTick(() => {
            this.$refs.materialForm.clearValidate();
            if (!this.materialForm.subjectId) {
              this.materialForm.subjectId = Number(this.$route.query.subjectId);
            }
            this.getQuestionTypeObj();
          });
        }
      } else {
        if (this.type == 1) {
          this.$refs.form.clearValidate();
        } else if (this.type == 2) {
          this.$refs.materialForm.clearValidate();
        }
      }
    },
    exit() {
      this.status = 1;
      this.$emit("statusChange", 1);
      this.$parent.itemData = null;
      this.$parent.itData = null;
      this.rectList = [];
      this.form = {
        isMaterial: 0,
        type: this.form.type,
        subjectId: this.form.subjectId,
        order: null,
      };
      this.appendFlag = false;
      this.coverList = [];
      this.$parent.sourceArea = "";
      this.$parent.indexObj = { index: null, ind: null };
      this.rectObj = null;
      this.bindMouseToPaperTool();
    },
    getQuestionTypeObj() {
      let data;
      if (this.type == "1") {
        data = this.$parent.allSubjectsQuestionType.find(
          (item) => item.subjectId == this.form.subjectId
        );
      } else if (this.type == "2") {
        data = this.$parent.allSubjectsQuestionType.find(
          (item) => item.subjectId == this.materialForm.subjectId
        );
      }
      let result;
      if (this.type == "1") {
        result = this.questionTypeObj.some((item) => item.id == this.form.type);
      } else if (this.type == "2") {
        result = this.questionTypeObj.some(
          (item) => item.id == this.materialForm.type
        );
      }
      if (!result) {
        this.questionTypeObj = data.questionType;
        if (this.type == "1") {
          this.form.type = this.questionTypeObj[0].id;
        } else if (this.type == "2") {
          this.materialForm.type = this.questionTypeObj[0].id;
        }
        this.changeRandomkey();
      }
    },
    cutQuestion(val) {
      const arr = this.$parent.examQuestionVOList.flatMap((item) => {
        if (item.subQuestions && item.subQuestions.length) {
          return item.subQuestions.map((it) => Number(it.order));
        }
        return Number(item.order);
      });
      const data = arr.filter((item) => item);
      this.newPage = val;
      this.scaleVisible = true;
      this.title = "划题";
      const subjectList = filterSubject(this.$parent.examPaper.level);
      this.subjectOptions = [...subjectList];
      let result = this.subject11.some(
        (item) => item.value == this.$route.query.subjectId
      );
      if (data.length) {
        this.form.order = Math.max(...data) + 1;
        this.materialForm.order = Math.max(...data) + 1;
      } else {
        this.form.order = 1;
        this.materialForm.order = 1;
      }
      this.$nextTick(() => {
        if (this.type == 1) {
          this.$refs.form.clearValidate();
        } else if (this.type == 2) {
          this.$refs.materialForm.clearValidate();
        }
        this.getQuestionTypeObj();
      });
      if (this.$parent.itemData) {
        this.form = { ...this.$parent.itemData };
        if (this.$parent.itemData.isMaterial === 1) {
          let { isMaterial, materialId, subjectId, type } =
            this.$parent.itemData;
          this.materialForm = {
            examPaperId: this.$route.query.id,
            materialId: materialId,
            subjectId: subjectId,
            type: type,
            sourceArea: this.resetRectList(),
          };
          if (isMaterial === 1) {
            this.type = "2"; // ok
          }
        } else {
          this.form = { ...this.$parent.itemData };
          this.form.examPaperId = this.$route.query.id;
          let list = this.resetRectList();
          this.form.sourceArea = JSON.stringify(list);
        }
      } else {
        if (result) {
          if (this.type == "1") {
            if (!this.form.subjectId) {
              this.form.subjectId = Number(this.$route.query.subjectId);
              this.getQuestionTypeObj();
            }
          } else if (this.type == "2") {
            if (!this.materialForm.subjectId) {
              this.materialForm.subjectId = Number(this.$route.query.subjectId);
              this.getQuestionTypeObj();
            }
          }
        }
      }
    },

    resetRectList() {
      let list = this.rectList.map((item) => {
        let maskList = [];
        if (item.maskList) {
          maskList = item.maskList.map((it) => {
            return {
              height: this.reverSize(it.height, item.ratio),
              left: this.reverSize(it.left, item.ratio),
              top: this.reverSize(it.top, item.ratio),
              width: this.reverSize(it.width, item.ratio),
              ratio: item.ratio ? item.ratio : 1,
            };
          });
        }
        return {
          height: this.reverSize(item.height, item.ratio),
          left: this.reverSize(item.left, item.ratio),
          top: this.reverSize(item.top, item.ratio),
          width: this.reverSize(item.width, item.ratio),
          priority: item.priority,
          maskList: maskList,
          ratio: item.ratio ? item.ratio : 1,
        };
      });
      return JSON.stringify(list);
    },
    formSave() {
      let formValid = this.type == "1" ? "form" : "materialForm";

      this.$refs[formValid].validate((valid) => {
        if (valid) {
          this.cutSave();
        }
      });
    },
    // setMaxWidth() {
    //   const sourceArea = JSON.parse(this.form.sourceArea);
    //   const subSourceArea = JSON.parse(this.form.subSourceArea);
    //   const widths = sourceArea.map((item) => Math.floor(item.width));
    //   const subWidths = subSourceArea.map((item) => Math.floor(item.width));
    //   const maxWidth = Math.max(...widths, ...subWidths);
    //   if (maxWidth > this.$parent.maxWidth) {
    //     this.$parent.maxWidth = maxWidth;
    //     changepapermaxwidth({
    //       maxWidth: Math.round(maxWidth),
    //       examPaperId: this.$route.query.id,
    //     });
    //   }
    // },
    cutSave() {
      this.btnLoading = true;
      if (this.type === "1") {
        // this.$parent.itemData存在时是修改 题目列表里已有试题
        // this.$parent.itData存在时 是修改材料小题
        this.form.sourceArea = this.resetRectList();
        this.form.examPaperId = this.$route.query.id;
        if (this.form.isMaterial == 1) {
          let data = {};
          if (this.$parent.itemData) {
            data = this.$parent.itemData;
          } else {
            data = this.materialList.find(
              (item) => item.order == this.form.order
            );
          }
          if (data) {
            if (this.$parent.itemData) {
              this.form.subOrder = this.$parent.itData.order;
              this.form.order = this.$parent.itemData.order;
              this.form.id = this.$parent.itData
                ? this.$parent.itData.id
                : data.id;
            }
            this.form.subjectId = data.subjectId;
            this.form.type = data.type;
            this.form.materialId = data.materialId;
            this.form.subSourceArea = this.resetRectList();
            this.form.sourceArea = data.sourceArea;
            this.$delete(this.form, "subQuestions");
            this.$delete(this.form, "show");
            this.$delete(this.form, "score");
          }
        } else {
          if (this.$parent.itData) {
            this.form = {
              id: this.$parent.itData.id,
              examPaperId: this.$route.query.id,
              order: this.$parent.itemData.order,
              subOrder: this.$parent.itData.order,
              subjectId: this.$parent.itemData.subjectId,
              type: this.$parent.itemData.type,
              isMaterial: this.$parent.itemData.isMaterial,
              materialId: this.$parent.itemData.materialId,
              sourceArea: this.$parent.itemData.sourceArea,
              subSourceArea: this.resetRectList(),
            };
          } else if (this.$parent.itemData) {
            this.form.id = this.$parent.itemData.id;
            this.form.order = this.$parent.itemData.order;
            this.form.isMaterial = 0;
            this.form.sourceArea = this.resetRectList();
            if (this.$parent.itemData.isMaterial == 1) {
              this.form.subOrder = this.$parent.itData.order;
              this.form.materialId = this.$parent.itemData.materialId;
              this.form.subSourceArea = this.resetRectList();
              this.form.sourceArea = this.$parent.itemData.sourceArea;
            }
            this.form.subjectId = this.$parent.itemData.subjectId;
            this.form.isMaterial = this.$parent.itemData.isMaterial || 0;
            this.form.type = this.$parent.itemData.type;
          } else {
            this.form.isMaterial = 0;
          }
        }
        this.$parent.loading = true;

        saveCut({ ...this.form })
          .then(() => {
            this.$message({
              showClose: true,
              message: "操作成功",
              type: "success",
            });
            this.$emit("submit");
            this.scaleVisible = false;
            // this.setMaxWidth();
            this.form = {
              isMaterial: 0,
              type: this.form.type,
              subjectId: this.form.subjectId,
              order: null,
            };

            this.btnLoading = false;
            if (!this.$parent.itemData) {
              this.bindMouseToPaperTool();
              this.exit();
            } else {
              this.sizeFlag = false;
            }
            this.appendFlag = false;
            this.$parent.loading = false;
            if (this.pageChange) {
              this.$parent.handleCurrentChange(this.newPage);
              this.pageChange = false;
            }
          })
          .catch((err) => {
            if (err.message === "该题号已被其他试题使用") {
              this.$emit("submit");
            }
            this.btnLoading = false;
            this.rectList = [];
            this.bindMouseToPaperTool();
            this.appendFlag = false;
            this.$parent.loading = false;
            this.status = 1;
            this.$emit("statusChange", 1);
            this.scaleVisible = false;
            this.form = {
              isMaterial: 0,
              type: this.form.type,
              subjectId: this.form.subjectId,
              order: null,
            };
            this.exit();
          });
      } else if (this.type === "2") {
        this.materialForm.sourceArea = this.resetRectList();
        this.materialForm.examPaperId = this.$route.query.id;
        if (this.$parent.itemData) {
          let itemData = this.$parent.itemData;
          this.materialForm.materialId = itemData.materialId;
          this.materialForm.sequence = itemData.sequence;
          this.materialForm.order = itemData.order;
          this.materialForm.subjectId = itemData.subjectId;
          this.materialForm.type = itemData.type;
        }
        this.$parent.loading = true;
        saveMaterial({ ...this.materialForm })
          .then(() => {
            if (!this.appendFlag && !this.sizeFlag) {
              this.status = 1;
              this.$emit("statusChange", 1);
              this.rectList = [];
              this.bindMouseToPaperTool();
              this.$emit("submit");
              this.$parent.itemData = null;
              // this.setMaxWidth();
            } else {
              this.status = 2;
              this.$emit("statusChange", 2);
              this.sizeFlag = false;
              this.$emit("submit");
            }
            this.$message({
              showClose: true,
              message: "操作成功",
              type: "success",
            });
            this.$parent.loading = false;
            this.scaleVisible = false;
            this.materialForm = {
              order: null,
              type: this.materialForm.type,
              subjectId: this.materialForm.subjectId,
            };
            this.btnLoading = false;
            this.appendFlag = false;

            if (this.pageChange) {
              this.$parent.handleCurrentChange(this.newPage);
              this.pageChange = false;
            }
            if (!this.$parent.itemData) {
              this.bindMouseToPaperTool();
              this.exit();
            } else {
              this.sizeFlag = false;
            }
          })
          .catch(() => {
            this.btnLoading = false;
            this.$parent.loading = false;
            this.rectList = [];
            this.appendFlag = false;
            this.bindMouseToPaperTool();
            this.status = 1;
            this.$emit("statusChange", 1);
            this.scaleVisible = false;
            this.materialForm = {
              isMaterial: 0,
              type: this.materialForm.type,
              subjectId: this.materialForm.subjectId,
              order: null,
            };
            this.exit();
          });
      }
    },
    del(item, index) {
      this.$confirm("删除后不可恢复，是否确定删除正在编辑的试题？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (!this.$parent.itemData) {
            this.rectList.splice(index, 1);
            if (!this.rectList.length) {
              this.status = 1;
              this.$emit("statusChange", 1);
              this.bindMouseToPaperTool();
              this.exit();
            }
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.coverList = [];
            this.$parent.itemData = null;
            this.$parent.sourceArea = "";
          } else {
            const { isMaterial, materialId } = this.$parent.itemData;
            if (isMaterial == 1) {
              this.rectList.splice(index, 1);
              if (!this.rectList.length) {
                deleteCut({
                  examPaperId: this.$parent.itemData.examPaperId,
                  questionOrder: this.$parent.itemData.order,
                })
                  .then(() => {
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                    this.$parent.getPaperMsg();
                    this.exit();
                  })
                  .catch(() => {
                    this.exit();
                  });
              } else {
                if (this.$parent.itemData.isSub) {
                  this.type = "1";
                  this.form.isMaterial = 1;
                } else {
                  this.type = "2"; // ok
                }
                this.materialForm.materialId = materialId;
                // this.$parent.sourceArea = "";

                this.cutSave();
              }
            } else {
              if (this.rectList.length === 1) {
                deleteCut({
                  examPaperId: this.$parent.itemData.examPaperId,
                  questionOrder: this.$parent.itemData.order,
                })
                  .then(() => {
                    this.rectList.splice(index, 1);
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                    this.$parent.getPaperMsg();
                    this.exit();
                  })
                  .catch(() => {
                    this.exit();
                  });
              } else {
                this.rectList.splice(index, 1);
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.cutSave();
              }
            }
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    coverMouseDown(e, index) {
      let el = this.$refs.cover[index];
      let disx = e.clientX;
      let disy = e.clientY;
      el.style.cursor = "grabbing";
      let that = this;
      this.$refs.container.onmousemove = function (event) {
        that.coverList[index].top =
          that.coverList[index].initY + event.clientY - disy;
        that.coverList[index].left =
          that.coverList[index].initX + event.clientX - disx;
      };
      this.$refs.container.onmouseup = function () {
        that.coverList[index].initY = that.coverList[index].top;
        that.coverList[index].initX = that.coverList[index].left;
        if (that.status === 3) {
          let item = correctCoverSize.bind(that)(index);
          if (item) {
            that.coverList.splice(index, 1, item);
            that.$forceUpdate();
            if (item.width < 10 || item.height < 10) {
              that.coverList.splice(index, 1, item);
            }
          } else {
            that.coverList.splice(index, 1);
          }
        }
        that.$refs.container.onmousemove = null;
        that.$refs.container.onmouseup = null;
        el.style.cursor = "default";
        return false;
      };
    },
    rectMouseDown(e, index) {
      let el = this.$refs.rectList[index];
      let disx = e.pageX;
      let disy = e.pageY;
      el.style.cursor = "grabbing";
      let that = this;
      this.$refs.container.onmousemove = function (event) {
        that.$nextTick(() => {
          let top = that.rectList[index].initY + event.pageY - disy;
          let left = that.rectList[index].initX + event.pageX - disx;
          if (
            top >= 0 &&
            top < that.imgObj.height - that.rectList[index].height
          ) {
            that.rectList[index].top = top;
          }
          if (
            left >= 0 &&
            left < that.imgObj.width - that.rectList[index].width
          ) {
            that.rectList[index].left = left;
          }
        });
      };
      this.$refs.container.onmouseup = function () {
        that.rectList[index].initY = that.rectList[index].top;
        that.rectList[index].initX = that.rectList[index].left;
        that.$refs.container.onmousemove = function () {};
        that.$refs.container.onmouseup = function () {};
        el.style.cursor = "default";

        return false;
      };
    },
    clearCover() {
      this.$confirm("是否确定清空所有遮挡?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.coverList = [];
          this.rectList[this.rectIndex].maskList = [];
          // this.bindMouseToPaperTool();
          if (this.$parent.itemData) {
            this.type = "1";
            if (this.$parent.itemData.isMaterial === 1) {
              this.type = "2"; // ok
              if (this.$parent.itData) {
                this.type = "1";
              }
            }
            this.cutSave();
          }
          this.status = 2;
          this.$emit("statusChange", 2);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    achiveCover() {
      this.rectList[this.rectIndex].maskList = this.coverList.map((item) => {
        return {
          ...item,
          top: item.top - this.rectList[this.rectIndex].top,
          left: item.left - this.rectList[this.rectIndex].left,
          initY: item.top - this.rectList[this.rectIndex].top,
          initX: item.left - this.rectList[this.rectIndex].left,
        };
      });
      if (this.$parent.itemData) {
        this.type = "1";
        if (this.$parent.itemData.isMaterial === 1) {
          this.type = "2"; //ok
          if (this.$parent.itData) {
            this.type = "1";
          }
        }
        this.cutSave();
      }
      this.status = 2;
      this.$emit("statusChange", 2);
    },
    addCover(index) {
      // 添加遮挡
      this.appendFlag = false;
      this.rectIndex = index;
      this.status = 3;
      this.$emit("statusChange", 3);
      this.$message({
        showClose: true,
        message: "请在裁剪区域内长按鼠标左键框选需要遮挡的内容！",
        type: "info",
      });
      if (this.rectList[index].maskList) {
        this.coverList = this.rectList[index].maskList.map((item) => {
          return {
            ...item,
            top: item.top + this.rectList[index].top,
            left: item.left + this.rectList[index].left,
            initY: item.top + this.rectList[index].top,
            initX: item.left + this.rectList[index].left,
          };
        });
      }
      this.bindMouseToPaperTool();
    },
    pointMouseDown(e, direction, index) {
      this.disY = e.clientY;
      this.disX = e.clientX;
      pointMouseDown.bind(this)(e, direction, index);
    },
    formateSize(val, ratio = 1) {
      const data = Number(val) * ratio;
      return data;
    },
    reverSize(val, ratio = 1) {
      const data = Number(val) / ratio;
      return data;
    },
    renderImg() {
      // 渲染图片并得到图片的原始宽高
      this.image = new Image();
      this.image.src = this.data;
      let that = this;
      this.image.onload = function () {
        that.imgObj = {
          naturalWidth: that.image.naturalWidth,
          naturalHeight: that.image.naturalHeight,
        };
        that.drawImage();
      };
    },
    drawImage() {
      const { width, height } = getComputedStyle(this.$refs.paperImg);
      this.imgObj.width = width.split("px")[0];
      this.imgObj.height = height.split("px")[0];
      this.$refs.rectRender.width = width.split("px")[0];
      this.$refs.rectRender.height = height.split("px")[0];
      this.ratioObj =
        Number(this.imgObj.width) / Number(this.imgObj.naturalWidth);
      this.setSourceArea();
    },
    handleClick(index) {
      operates[index].bind(this)();
    },
    handleClose() {
      this.scaleVisible = false;
    },
    submitScale() {
      if (this.scaleNum != 0) {
        this.scaleRatio = this.scaleNum;
        this.scaleVisible = false;
      } else {
        this.$message({
          showClose: true,
          message: "放大/缩小倍数禁止为0或空",
          type: "warning",
        });
      }
    },
    bindMouseToPaperTool() {
      // 给试题dom 绑定鼠标按下事件 获取起始坐标
      this.$refs.container.onmousedown = this.onmousedown;
    },
    onmousedown(e) {
      this.x = e.offsetX;
      this.y = e.offsetY;
      this.$refs.container.onmousemove = this.onmousemove;
      this.$refs.container.onmouseup = this.mouseup;
      this.setPointerEventsNone();
    },
    onmousemove(e) {
      this.drawRect(e);
    },
    drawRect(e) {
      // 绘制框线 绘制完成后 清除框线 将用div 代替canvas
      const canvas = this.$refs.rectRender;
      this.rectCtx = canvas.getContext("2d");
      this.rectCtx.clearRect(0, 0, canvas.width, canvas.height);
      this.rectCtx.beginPath();
      if (this.status === 1) {
        this.rectCtx.strokeStyle = "#2474ED";
      } else if (this.appendFlag) {
        this.rectCtx.strokeStyle = "#2474ED";
      } else if (this.status === 3) {
        this.rectCtx.strokeStyle = "#ff8d19";
      }
      this.rectCtx.lineWidth = 1;
      this.rectCtx.strokeRect(
        this.x,
        this.y,
        e.offsetX - this.x,
        e.offsetY - this.y
      );
      if (e.offsetY - this.y > 0) {
        this.rectObj = {
          left: e.offsetX - this.x > 0 ? this.x : e.offsetX,
          top: e.offsetY - this.y > 0 ? this.y : e.offsetY,
          width: Math.abs(e.offsetX - this.x),
          height: Math.abs(e.offsetY - this.y),
          initHeight: Math.abs(e.offsetY - this.y),
          initY: this.y,
          initWidth: Math.abs(e.offsetX - this.x),
          initX: this.x,
          priority: this.page,
          maskList: [],
          ratio: this.ratioObj,
        };
      } else {
        this.rectObj = {
          left: e.offsetX - this.x > 0 ? this.x : e.offsetX,
          top: e.offsetY - this.y > 0 ? this.y : e.offsetY,
          width: Math.abs(e.offsetX - this.x),
          height: this.y - e.offsetY,
          initHeight: this.y - e.offsetY,
          initY: e.offsetY,
          initWidth: Math.abs(e.offsetX - this.x),
          initX: this.x,
          priority: this.page,
          maskList: [],
          ratio: this.ratioObj,
        };
      }
    },
    mouseup() {
      if (this.rectObj.width > 10 && this.rectObj.height > 10) {
        if (this.status === 1) {
          this.rectList.push(this.rectObj);
          const canvas = this.$refs.rectRender;
          this.rectCtx.clearRect(0, 0, canvas.width, canvas.height);
          this.initPaperToolEvent();
          this.status = 2;
          this.$emit("statusChange", 2);
        } else if (this.appendFlag) {
          this.rectList.push(this.rectObj);
          const canvas = this.$refs.rectRender;
          this.rectCtx.clearRect(0, 0, canvas.width, canvas.height);
          this.initPaperToolEvent();
          if (this.$parent.itemData) {
            if (this.$parent.itemData.isMaterial === 1) {
              if (this.$parent.itData) {
                this.type = "1";
              } else {
                this.type = "2"; // ok
              }
            } else {
              this.type = "1";
            }
            this.cutSave();
          }
        } else if (this.status === 3) {
          const canvas = this.$refs.rectRender;
          const data = correctCoverSize.bind(this)();
          this.coverList.push(data);
          this.rectCtx.clearRect(0, 0, canvas.width, canvas.height);
          this.initPaperToolEvent();
          this.bindMouseToPaperTool();
        }
      } else {
        const canvas = this.$refs.rectRender;
        this.rectCtx.clearRect(0, 0, canvas.width, canvas.height);
        this.$message({
          showClose: true,
          message: "框选范围过小，请重新框选",
          type: "warning",
        });
        this.initPaperToolEvent();
        this.bindMouseToPaperTool();
      }
    },
    initPaperToolEvent() {
      this.$refs.container.onmousedown = null;
      this.$refs.container.onmousemove = null;
      this.$refs.container.onmouseup = null;
      this.initPointerEvents();
    },
    setPointerEventsNone() {
      // 设置pointer-event属性 避免触发框线内 e.offset
      this.pointerEvents = "none";
    },
    initPointerEvents() {
      this.pointerEvents = "inherit";
    },
  },
};
</script>
<style lang="scss" scoped>
.paperTool-container {
  display: flex;
  align-items: flex-start;
  .paper-box {
    width: 100%;
    height: 688px;
    overflow: auto;
    background: #eee;
    background-image: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.25) 25%,
        transparent 0,
        transparent 75%,
        rgba(0, 0, 0, 0.25) 0
      ),
      linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.25) 25%,
        transparent 0,
        transparent 75%,
        rgba(0, 0, 0, 0.25) 0
      );
    background-size: 30px 30px;
    background-position: 0 0, 15px 15px;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #dee1e7;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0px #e8e8e8;
      background-color: #fafafa;
      border: 1px solid #e8e8e8;
    }
    .paperTool {
      width: 100%;
      .box {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        .img {
          max-width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          user-select: none;
          transform-origin: 0 0;
          // -webkit-user-drag: none;
        }
        #rectRender {
          position: absolute;
          top: 0;
          left: 0;
        }
        .modal-mask {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;

          .modal-mask-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            background-color: rgba(16, 16, 17, 0.4);
          }
        }
        .rect {
          position: absolute;
          border: 2px solid #2474ed;
          transition: all linear;
          // z-index: 999;
          .rect-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            &:hover .rectOperate {
              opacity: 1;
            }
            .rectOperate {
              opacity: 0;
              position: absolute;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              color: #ffffff;
              font-size: 14px;
              right: 0;
              bottom: 0;
              transform: translateY(100%);
              z-index: 999;
              max-width: 100%;
              flex-wrap: wrap;
              .rectOperate-item {
                cursor: pointer;
                white-space: nowrap;
                height: 38px;
                line-height: 38px;
                box-sizing: border-box;
                padding: 0 9px;
                text-align: center;
                background: rgba(16, 16, 17, 0.6);
                z-index: 99999;
              }
              & :last-child {
                margin-right: 0;
              }
            }
            .cover-block {
              background-color: #fff;
              position: absolute;
            }
            .rect-point {
              position: absolute;
              width: 10px;
              height: 10px;
              background: #419eff;
            }
            .n {
              top: -5px;
              left: 50%;
              transform: translateX(-50%);
              cursor: n-resize;
            }
            .s {
              bottom: -5px;
              left: 50%;
              transform: translateX(-50%);
              cursor: s-resize;
            }
            .e {
              right: -5px;
              top: 50%;
              transform: translateY(-50%);
              cursor: e-resize;
            }
            .w {
              left: -5px;
              top: 50%;
              transform: translateY(-50%);
              cursor: w-resize;
            }
            .nw {
              left: -5px;
              top: -5px;
              cursor: nw-resize;
            }
            .ne {
              right: -5px;
              top: -5px;
              cursor: ne-resize;
            }
            .sw {
              left: -5px;
              bottom: -5px;
              cursor: sw-resize;
            }
            .se {
              right: -5px;
              bottom: -5px;
              cursor: se-resize;
            }
          }
        }
        .cover {
          position: absolute;
          border: 2px solid #ff8d19;
          transition: all linear;
          cursor: grab;
          background-color: #fff;
          z-index: 2;
          .cover-wrapper {
            position: relative;
            width: 100%;
            height: 100%;

            .cover-point {
              position: absolute;
              width: 10px;
              height: 10px;
              background: #ff8d19;
            }
            .n {
              top: -5px;
              left: 50%;
              transform: translateX(-50%);
              cursor: n-resize;
            }
            .s {
              bottom: -5px;
              left: 50%;
              transform: translateX(-50%);
              cursor: s-resize;
            }
            .e {
              right: -5px;
              top: 50%;
              transform: translateY(-50%);
              cursor: e-resize;
            }
            .w {
              left: -5px;
              top: 50%;
              transform: translateY(-50%);
              cursor: w-resize;
            }
            .nw {
              left: -5px;
              top: -5px;
              cursor: nw-resize;
            }
            .ne {
              right: -5px;
              top: -5px;
              cursor: ne-resize;
            }
            .sw {
              left: -5px;
              bottom: -5px;
              cursor: sw-resize;
            }
            .se {
              right: -5px;
              bottom: -5px;
              cursor: se-resize;
            }
          }
        }
      }
    }
  }
  .coverOperate {
    position: absolute;
    height: 38px;
    box-sizing: border-box;
    padding: 0 18px;
    background: rgba(16, 16, 17, 0.6);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    font-size: 14px;
    right: 0;
    bottom: -38px;
    width: fit-content;
    z-index: 1;
    .coverOperate-item {
      cursor: pointer;
      white-space: nowrap;
      margin-right: 24px;
    }
    & :last-child {
      margin-right: 0;
    }
  }
  .paper-operate {
    margin: 0 18px;
    box-sizing: border-box;
    padding: 18px 0;
    .paper-operate-item {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #cecece;
      margin-bottom: 8px;
      cursor: pointer;
      text-align: center;
      line-height: 32px;
      i {
        font-size: 18px;
        color: #000000;
        font-weight: bold;
      }
    }
  }
}
.saveForm {
  box-sizing: border-box;
  padding: 50px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .footer {
    text-align: center;
  }
}
.err-tip {
  border: 1px dashed #ffb5b4;
  border-radius: 4px;
  background: #fff3f3;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  color: #292626;
  font-size: 14px;
  margin-bottom: 18px;
}
</style>
