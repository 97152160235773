<template>
  <div class="clearAll">
    <el-button type="text" @click="deleteAll">
      <i class="el-icon-delete"></i> 一键清空</el-button
    >
    <el-dialog
      title="温馨提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        此操作将清空当前试卷所有已经裁切好的试题，确认清空请输入密码，否则点击取消
      </div>
      <div class="password">
        请输入登录密码：<el-input
          v-model="password"
          placeholder="请输入登录密码"
          type="password"
          style="width: 150px"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { clearcutquestion } from "@/core/api/exam/exampaper";
import { encryption } from "@/core/util/util";
export default {
  name: "ClearAll",
  data() {
    return {
      dialogVisible: false,
      password: null,
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    deleteAll() {
      this.dialogVisible = true;
      this.password = null;
    },
    async submit() {
      if (this.password) {
        let userInfo = {
          randomStr: "blockPuzzle",
          code: "xxx",
          password: this.password,
        };
        const user = encryption({
          data: userInfo,
          key: "pigxpigxpigxpigx",
          param: ["password"],
        });
        await clearcutquestion({
          examPaperId: this.$route.query.id,
          password: user.password,
        }).catch(() => {
          this.dialogVisible = false;
        });
        this.dialogVisible = false;
        this.$emit("getData");
      } else {
        this.$message.warning("请输入登录密码");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.clearAll {
  font-size: 14px;
  margin-left: 12px;
  margin-bottom: 0.5rem;
}
</style>
